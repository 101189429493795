import { useEffect, useState } from 'react'

const gql = String.raw

const deets = `
    name
    _id
    image {
      asset {
        url
        metadata {
          lqip
        }
      }
    }
`

export default function useLatestData() {
  // hot slices
  const [hotSlices, setHotSlices] = useState()
  // slicemasters
  const [slicemasters, setSlicemasters] = useState()
  // Use a side effect to fetch data from the graphql endpoint
  useEffect(function () {
    fetch(process.env.GATSBY_GRAPHQL_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: gql`


          query {
            StoreSettings(id: "downtown") {
              name
              slicemaster {
                ${deets}
              }
              hotSlices {
                ${deets}
              }
            }
          }
        `,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        // TODO: check for errors
        // set the data to state
        setSlicemasters(res.data.StoreSettings.slicemaster)
        setHotSlices(res.data.StoreSettings.hotSlices)
        // when the component loads, fetch the data
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  return {
    hotSlices,
    slicemasters,
  }
}
